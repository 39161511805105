.container11 {
  position: relative;
}

$step-content-width: 60px;
$border-of-snake-width: 20px;
$border-of-snake: #{$border-of-snake-width} solid #e2d2fe;
$border-of-snake-radius: 60px;

$horizontal-between-items: #{$border-of-snake-width} dashed #f7aa40;
$horizontal-between-rows: #{$border-of-snake-width} dashed #de5e00;
$vertical-row-edge: #{$border-of-snake-width} dashed #962e00;
$horizonta-row-edge: #{$border-of-snake-width} dashed #ce0800;

/* Comment this to match borders */
$horizontal-between-items: $border-of-snake;
$horizontal-between-rows: $border-of-snake;
$vertical-row-edge: $border-of-snake;
$horizonta-row-edge: $border-of-snake;

.steps-list-looped {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px $border-of-snake-radius;

  .step-wrapper {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: stretch;
    flex-basis: 33%;
    margin-bottom: 0px;
    height: 24rem;

    &::before,
    &::after {
      content: "";
      display: block;
      flex-basis: 100%;
    }

    &::before {
      height: 54.5%;
      border-bottom: $horizontal-between-items;
    }

    &::after {
      align-self: self-end;
      margin-top: auto;
      margin-bottom: -20px;
      height: calc(50% + 30px);
      border-top: $horizontal-between-items;
    }

    .step-content1 {
      align-self: center;
      position: relative;
      padding: 4px 10px 4px 10px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0px 5px #00000047;
      margin: 4.5rem -1rem;
    }

    .step-content {
      flex-shrink: 0;
      flex-basis: $step-content-width;
      // margin: 50px auto;
      align-self: center;
      position: relative;
    }

    border-bottom: $horizontal-between-rows;

    /* You can style first and last element here */
    &:first-child {
      &::before {
        border-bottom-color: transparent;
        border-top-color: transparent;
        border-left-color: transparent;
      }
    }

    &:last-child {
      &::after {
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-right-color: transparent;
      }
    }
  }
}

$number-of-cols: 1, 2, 3, 4;
$breakpoints-media: "only screen and (max-width : 399px)",
  "only screen and (min-width : 400px) and (max-width : 749px)",
  "only screen  and (min-width : 750px) and (max-width: 999px)",
  "only screen  and (min-width : 1000px)";
$bp: zip($number-of-cols, $breakpoints-media);

@each $breakpoint-cols, $breakpoint-media in $bp {
  @media #{$breakpoint-media} {
    .step-wrapper {
      flex-basis: calc(100% / $breakpoint-cols) !important;

      &:nth-child(#{$breakpoint-cols}n) {
        &::after {
          margin-right: -$border-of-snake-radius;
          border-bottom: $horizonta-row-edge;
          border-right: $vertical-row-edge;
          border-top-right-radius: $border-of-snake-radius;
          border-bottom-right-radius: $border-of-snake-radius;
          height: 14.5rem;
        }
      }

      &:nth-child(#{$breakpoint-cols}n + 1) {
        &::before {
          margin-left: -$border-of-snake-radius;
          border-top: $horizonta-row-edge;
          border-left: $vertical-row-edge;
          border-top-left-radius: $border-of-snake-radius;
          border-bottom-left-radius: $border-of-snake-radius;
          height: 14.5rem;
          margin-top: -2rem;
        }
      }
    }

    $imax: $breakpoint-cols + 1;

    @for $i from 1 through $imax {
      /* Last line */
      $butNot: "#{$breakpoint-cols}n + #{$breakpoint-cols}";

      .step-wrapper:nth-last-child(#{$i}):nth-child(#{$butNot})
        ~ .step-wrapper {
        border-bottom-color: transparent;
      }
    }

    @for $i from 1 through $breakpoint-cols {
      /* First line (ex. 4 in row, but there is only 3) */
      $butNot: "-n + #{$imax - $i}";

      .step-wrapper:nth-child(#{$i}):nth-last-child(#{$butNot}) {
        border-bottom-color: transparent;
      }
    }
  }
}

/* Custom styling */
.step-number {
  color: #444;
  font-size: 15px;
  font-weight: 900;
  border-radius: 50%;
  margin: 5px -2px;
  box-shadow: 0 0px 7px #00000052;
  background-color: #ffe063;
  height: 6rem;
  width: 6rem;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 4px solid white;
}

.step-description {
  font-size: 11px;
  width: 15rem;
  position: absolute;
  top: -2.5rem;
  left: -4.5rem;
  display: flex;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  line-height: normal;
  height: 1rem;
}

.step-header {
  font-size: 11px;
  width: 15rem;
  position: absolute;
  top: 8.2rem;
  left: -4.5rem;
  display: flex;
  gap: 0.3rem;
  justify-content: center;
  align-items: center;
  line-height: normal;
}

.FlagStyle {
  position: absolute;
  top: -20px;
  width: 4rem;
  left: 40px;
}

.ArrowTop {
  position: absolute;
  top: -35px;
  left: -9px;
}

.ArrowBottom {
  position: absolute;
  bottom: -35px;
  left: -9px;
  rotate: 180deg;
}

.button-style {
  padding: 0 !important;
  min-width: auto !important;
  text-transform: capitalize !important;
  border-radius: 50% !important;
}

.ArrowRightIconStyle {
  position: absolute;
  top: 2.3rem;
  right: 8rem;
  color: gray;
}

.step-total-points {
  padding: 7px;
  margin: 3px;
  border-radius: 50%;
  background: linear-gradient(190deg, #ffe5b0 0%, #ffae0c 100%);
  font-size: 12px;
}
.completedTick {
  color: #43b56d;
}
