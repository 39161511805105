.mainContainer{
    display: flex;
    flex-direction: column;
}
.paper{
    border-radius: 8px;
}
.messageContainer{
    display: flex;
    gap: 2rem;
    align-items: center;
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 1rem;
    }
.circlePic{
    border-radius: 50%;
    max-width: 4rem;
    max-height: 4rem;
}
.nameContainer{
    display: flex;
    align-items: center;
    width: 100%;
}
.backIcon{
    max-width: 3rem;
    max-height: 3rem;
}
.backIconContainer{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.nameMessageBox{
    display: flex;
    flex-direction: column;
}
.textBox{
    min-width: 100%;
    height: 5rem;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
}
.postButton{
    background-color: #2591CE;
    border: none;
    color: white;
    border-radius: 4px;
    padding: 0.5rem;
    font-size: 14px;
    font-weight: bold;
    width: 7rem;
}