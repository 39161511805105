/* FileUploader.css */

.file-uploader {
  border: 2px dashed #aaa;
  padding: 20px;
  text-align: center;
}

.file-container {
  display: flex;
  overflow-x: auto;
  margin-top: 10px;
}

.file-item {
  position: relative;
  margin-right: 10px;
}

.file-preview {
  max-width: 100px;
  max-height: 100px;
}

.remove-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  font-size: 12px;
  line-height: 1;
}

.add-file-button {
  color: var(--color-button-background);
  cursor: pointer;
  font-size: 1.8rem;
  border: 1px solid var(--color-button-background);
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 2rem;
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 0.5rem;
}