.feedback {
    display: flex;
    align-items: center;
    gap: 20px; 
}
.feedback-heading{
    width: 150px;
}
.star-icon{
    width: 100%;
}
.inputFieldName {
    min-width: 120px;
    text-align: right;
}
.inputFieldBox {
    width: 30rem;
}

.inputFieldBox > div > input {
    font-size: 1.4rem;
    font-weight: 500;
}

@media screen and (max-width: 500px) {
    .feedback {
        display: grid;
    }
    .feedback > div > span{
        margin: 0;
    }
    .inputField {
        display: block!important;
    }
    .inputFieldName {
        min-width: 116px;
        text-align: left;
        margin: 1rem 0rem;
    }
    .inputFieldBox {
        width: 100%;
    }
}