html {
  font-size: 62.5%;
}

body {
  line-height: 1.3;
  font-size: 1.6rem;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: myfontregular!important;
}

body::-webkit-scrollbar {
  // display: none !important;
  width: 5px !important; /* width of the entire scrollbar */
  background-color: transparent;
  transition: all 5s ease-out;
}
// body:hover::-webkit-scrollbar {
//   display: block !important;
//   width: 5px !important; /* width of the entire scrollbar */
//   background-color: transparent !important;
//   transition: all 5s ease-out;
// }
body::-webkit-scrollbar-track {
  background: transparent !important; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc !important; /* color of the scroll thumb */
  border-radius: 8px !important; /* roundness of the scroll thumb */
  border: 1px solid #fff !important; /* creates padding around scroll thumb */
}
