.card-sm {
  h3 {
    font-size: 1.6vw;
    margin: 0rem 1rem;
    text-align: center;
    @media screen and (max-width: 500px) {
      font-size: 2rem!important;
    }
    @media screen and (max-width: 770px) {
      font-size: 1.6rem;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  // width: 100%;
  height: 0;
  border-radius: 0.8rem;
  color: #fff;
  padding: 22.125% 0;
  font-weight: bolder;
  font-size: 18px;
  font-family: inherit;
  // margin: 8px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .back-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container,
.smcard div {
  width: 425px;
  height: 215px;
}

.container {
  position: absolute;
  perspective: 1000;
  margin-top: -135px;
  top: 50%;
}

.card {
  position: relative;
  transition: 0.6s;
  transform-style: preserve-3d;
}

.smcard div {
  background: transparent;
  color: #fff;
  backface-visibility: hidden;
  overflow: hidden;
  position: absolute;
  top: 58px;
  left: 0;
  text-align: center;
  cursor: pointer;
  transition: 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.9);
}

.smcard div .front {
  transform: rotateY(0deg);
  z-index: 2;
}

.smcard div.back,
.card.flipped {
  transform: rotateY(180deg);
}

.smcard div img {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 0.7;
}

.smcard div .caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  padding: 2em;
  backface-visibility: hidden;
}

.front h2 {
  word-spacing: -0.15em;
  font-weight: 300;
  font-size: 1.6em;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #272833;
  transition: transform 0.35s, color 0.35s;
  transform: translate3d(0, -50%, 0);
}

.front h2 span {
  font-weight: bold;
}
