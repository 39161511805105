.e-edit,
.e-delete,
.e-popup-content {
  display: none !important;
}
.titleStyle {
  font-size: 15px;
  //font-weight: bold;
  margin-left: 50px;
  margin-bottom: 10px;
  //margin-top: 40px !important;
}
.eventsDivider {
  margin-top: 20px;
}

.iconBottom {
  border: none;
  float: left;
  margin-top: 12px;
  margin-left: 0px;
  width: 40px;
  background-color: white;
  height: 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: medium;
  box-shadow: 2px 2px 2px grey;
  margin-right: 20px;
  text-align: center;
  position: relative;
}

.iconTop {
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  border: 1px solid;
  float: left;
  margin-top: -5px;
  width: 40px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: orangered;
  color: white;
  margin-right: 20px;
  text-align: center;
  box-shadow: 2px 2px 2px grey;
}

.e-time {
  display: none !important;
}
