.Layoutmain {
  display: flex;
  //grid-template-rows: auto 1fr auto;
  flex-direction: column;
}

.mycontainer {
  min-height: 90vh;
  // margin-top: 7rem;
}

// DarkMode styles starts
.darkMode {
  background-color: #1C2027;
  color: white;
}

.darkMode .MuiPaper-root {
  background-color: #3F434D;
  color: white;
}

.darkMode .modulesObject {
  color: white !important;
}

.darkMode input {
  // background-color: #565d6e;
  color: white;
}

.darkMode ol li a {
  color: white !important;
}

.darkMode ol {
  color: white !important;
}

.darkMode .arrow {
  border-top: 3px solid #ffffff !important;
  border-right: 3px solid #ffffff !important;
}

.darkMode td a {
  color: white !important;
}

.darkMode .bookmark-icon {
  color: white;
}

.darkMode .bookmark-icon.fill {
  fill: yellow !important;
}

.darkMode .apexcharts-legend-text {
  color: #ffffff !important;
}

.darkMode .apexcharts-menu-item {
  color: #000000 !important;
}

.darkMode .apexcharts-tooltip.apexcharts-theme-light {
  color: #000000 !important;
}

.darkMode moduleSection {
  background-color: #1a1a1a;
  color: white;
}

.darkMode .carousel-container {
  background-color: #282D37;
  color: white;
  box-shadow: none;
}

.darkMode .swiper-wrapper>div>div {
  background-color: #3F434D;
  color: white;
}

// Dark mode calendar
.darkMode .e-schedule {
  background-color: #1a1a1a;
  color: white;
}

.darkMode .e-toolbar .e-tbar-btn {
  background-color: #1a1a1a;
  color: white;
}

.darkMode .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: white !important;
}

.darkMode .e-toolbar .e-tbar-btn.e-btn .e-icons {
  color: white !important;
}

.darkMode .e-schedule .e-schedule-toolbar .e-toolbar-items {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-month-view .e-work-cells,
.e-schedule .e-month-agenda-view .e-work-cells {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-month-view .e-date-header-wrap table td,
.e-schedule .e-month-agenda-view .e-date-header-wrap table td {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-vertical-view .e-left-indent-wrap table tbody td {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-vertical-view .e-date-header-wrap table tbody td {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-vertical-view .e-time-cells-wrap table td {
  background-color: #272C38 !important;
  color: white;
}

.darkMode .e-schedule .e-vertical-view .e-work-cells {
  background-color: #272C38 !important;
  color: white;
}

// Dark mode calender ends

// DarkMode styles ends

.main {
  background-color: transparent;
  padding-bottom: 5rem;
  // margin-top: 4rem;

  //margin-bottom: 4rem;

  // margin: 4rem 0;
  // @media screen and (max-width: 360px) {
  //   margin: 0rem 0;
  // }
  // min-width: 100vh;
  .slide-container {
    // background-color: transparent;
    // padding: 1rem 4rem;
    // margin: 1rem 0rem;

    .slider-container {
      display: flex;
      padding: 0.2rem 0;

      // background-color: transparent;
      &:focus {
        outline: 2px solid white !important;
        border: 1px solid white;

        &>div {
          outline: 2px solid !important;
          border: 1px solid black;
        }
      }
    }
  }
}

.layout_container {
  padding: 3rem;
  background-color: var(--color-grey-light-5);
  box-shadow: var(--shadow-drak);
}



// Dark and Light Mode

#light {
  background-color: #f3ebf6;
}

#dark {
  background-color: #121212;
}

.switch label {
  margin: 10px;
}

#light .switch label {
  color: black;
}

#dark .switch label {
  color: white;
}

.mainContent {
  border-radius: 10rem;
  padding: 1.5rem 2rem;
}

.switch {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch label {
  margin: 10px;
}

.MuiTableCell-root {
  font-family: myfontregular !important;
}