
.maindiv{
    width:100%;
    height: 100%;
    background: transparent;
    display: flex;
    align-items: center;
    
    justify-content: center;
}

.maincontainer {
    width: 50%;
    height: fit-content;
    border-radius: 5px;
    background: white;
    justify-content: center;
    margin: auto;
    padding: 20px;
}

.iconcontainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.create{
    font-size: 20px;
    color: #000 !important;
    font-weight: 400;
    padding: 0px;
    margin: 0px;
}

.form{
    padding: 20px;
    text-align: center;
    margin: 10px auto;
}

.field{
    display: flex;
    //justify-content: flex-end;
    align-content: center;
    margin: auto;
    gap: 20px;
}   

.submit{
    background: var(--button-background);
  border: none;
  border-radius: 5px;
  height: 25px;
  width: 70px;
  color: var(--button-text);
  font-family: "nunito";
  font-weight: 500;
  font-size: 18px;
}

.submit:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.closebutton:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.btn_color{
    background: var(--color-button-background);
    padding: 10px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: white;
    font-weight: 300px;
    border-radius: 5px;
    border: none;
    margin: 0px;
}
.btn_colorClose{
    background: white;
    padding: 8px 20px;
    width: fit-content;
    height: fit-content;
    font-size: 14px;
    color: var(--color-button-background);
    border:2px solid  var(--color-button-background);
    font-weight: 300px;
    border-radius: 5px;
    margin: 0px;
}

.btn_colordis {
    background-color: grey;
    color: white;
    cursor: not-allowed;
    font-family: "nunito";
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    padding: 0px 20px;
    border: none;
    height: 30px;
    width: fit-content;
  }

  .btn_color:hover{
    cursor: pointer;
    
}

.selectedAvt{
    border: 4px solid var(--color-button-background);
}

