/* styles.css */


.addButton {
  color: var(--color-button-background);
  cursor: pointer;
  font-size: 1.8rem;
  border: 1px solid var(--color-button-background);
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 2rem;
  align-items: center;
  display: flex;
  font-weight: 600;
  gap: 0.5rem;
  margin: 2rem auto 2rem;
}

.divider {
  margin: 20px 0;
  border-bottom: 1px solid #ccc;
}

.selectField {
  padding: 8px;
  font-size: 16px;
  margin-left: 1rem;
  border-radius: 4px;
}