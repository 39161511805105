.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-label {
  position: relative;
  padding-left: 30px; /* Adjust the padding as needed */

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 20px; /* Adjust the width as needed */
    height: 20px; /* Adjust the height as needed */
    border: 2px solid #000; /* Adjust the border color and width as needed */
    border-radius: 4px; /* Adjust the border radius as needed */
    box-sizing: border-box;
  }

  & .MuiRadio-root {
    display: none; /* Hide the default radio button */
  }

  & .MuiRadio-root:checked + span:before {
    background-color: #000; /* Adjust the background color as needed */
  }
}