#liveSession {
    text-align: right;
    border-collapse: separate;
    /* border-spacing: 10px 10px; */
}
#liveSession tbody tr td:nth-child(2){
    text-align: left;
}
#liveSession > tbody > tr > td:first-child {
    font-weight: 600;
}
#liveSession > tbody > tr > td > a {
    font-weight: 700;
    margin: 0rem 2rem;
    text-decoration: none;
}
.topicImage {
    border-radius: 4px;
    width: 40%;
}
@media screen and (max-width: 900px) {
    .topicImage {
        width: 100%;
    }
}
@media screen and (max-width: 500px) {
    #liveSession {
        text-align: left;
        width: 100%;
        padding: 0px 5px;
    }
    #liveSession > tbody > tr {
        /* display: grid;
        width: max-content; */
        margin: 1rem 0rem;
    }
}
@media screen and (min-width: 900px) and (max-width: 1300px) {
    #liveSession {
        text-align: left;
        width: 100%;
        padding: 0px 5px;
    }
    #liveSession > tbody > tr > td > a {
        margin: 0rem;
    }
    #liveSession > tbody > tr {
        display: grid;
        /* width: max-content; */
        margin: 1rem 0rem;
    }
}