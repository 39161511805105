.progress-bar {
    height: 10px;
    width: 100%;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
  }
  
  .progress-bar-text {
    margin: 0 10px;
    font-size: 12px;
    color: #fff;
  }