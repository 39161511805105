.poll{
    position: relative;
    width: 100%;
    padding: 0 1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.poll .question{
    padding: 20px;
    color: #111;
    font-size: 1.5em;
    border-bottom: 1px solid #eee;
}

.poll .answers{
    padding: 10px 0;
}

.poll .answers .answer{
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border: 1px solid #d4d4d4;
    cursor: pointer;
    overflow: hidden;
    border-radius: 3px;
    margin-bottom: 10px;
}

.poll .answers .answer.selected{
    border: 2px solid var(--color-button-background);
}

.poll .answers .answer.selected span.percentage_bar{
    background: #DCF2FF!important;
}

.poll .answers .answer span.percentage_value
{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 40px;
    color: #111;
    font-size: 15px;
}

.poll .answers .answer span.percentage_bar{
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #E5E5E5;
    z-index: -2;
    transition: width 200ms ease-in-out;
}