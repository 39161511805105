.carousel-container {
  // margin-top: 3rem;
  padding: 1.5rem 0rem;
  // background-color: #ffffff00;
  background-color: #ffffff;
  border-radius: 5px;
  // box-shadow: #8b8b8b69 0px 25px 50px -12px;
  box-shadow: 0px 0px 20px 0px #c3d0dc;
  .heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .swiper_wrapper {
    margin: 1rem 5rem;
    position: relative;
    // height: 30rem;
    @media screen and (max-width: 500px) {
      margin: 1.5rem 3rem;
    }
    @media screen and (max-width: 376px) {
      margin: 1.5rem 2rem;
    }
    .arrow {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      cursor: pointer;
      @media screen and (max-width: 500px) {
        width: 15px;
        height: 15px;
      }
    }
    .arrow-right {
      transform: rotate(45deg);
      position: absolute;
      right: -30px;
      top: 50%;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: 500px) {
        right: -20px;
      }
      @media screen and (max-width: 376px) {
        right: -13px;
      }
      :disabled {
        opacity: 0.5;
      }
    }
    .arrow-left {
      transform: rotate(-135deg);
      position: absolute;
      left: -30px;
      top: 50%;
      &:focus {
        outline: none;
      }
      @media screen and (max-width: 500px) {
        left: -20px;
      }
      @media screen and (max-width: 376px) {
        left: -13px;
      }
      :disabled {
        opacity: 0.5;
      }
    }
    .hide {
      display: none;
    }
  }
  .carousel-heading {
    padding-left: 2rem;
    font-weight: 600;
    font-size: 2rem;

    @media screen and (max-width: 500px) {
      padding-left: 1.5rem;
    }
  }
  .count-heading {
    font-size: 1.8rem;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 4px 0px #c3d0dc;
    border-left: 3.5px solid var(--color-primary);
    color: var(--color-primary);
    background-color: #ffffff;
    border-radius: 4px;
  }
  &--between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .slick-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    gap: 20px;
    position: relative;

    .text {
      font-weight: 600;
      font-size: 22px;
    }
  }
  .flex-loading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    overflow: hidden;
  }
}

.viewAllImg {
  font-size: 1.7rem;
  display: flex;
  font-weight: 600;
  cursor: pointer;
  height: 32px;
}
.viewAllImgBtn {
  margin: 0 2rem 0 0!important;
  background: transparent;
  // border: 1px solid white;
  border: none;

  &:focus {
    outline: 2px solid!important;
    // border: 1px solid black;
  }
}