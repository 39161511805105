.steps {
    display: grid;
    grid-template-columns: repeat(7, 13.8%);
    grid-column-gap: 2px;
    margin-top: 2rem;

    .step {
        /* box-shadow: 0px 2px 4px 0px #C3C4CA; */
        border-radius: 1px;
        background-color: #319f002e;
        height: 12px;
        background-size: 200% 100%;

        &.step-color {
            background-image: linear-gradient(to right, #319F00 50%, #319f002e 50%);
            animation: cambioColor 1s linear;
        }
    }
}

@keyframes cambioColor {
    0% {
        background-position-x: 99%;
    }

    100% {
        background-position-x: 0%;
    }
}



  
  .hexagon {
    position: relative;
    width: 145px;
    height: 100px;
    background: linear-gradient(to bottom, #E3EFFE 50%, #C6D9FA 50%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    border: 2px solid #C5D1F2;
  }
  
  .hexagon-inner {
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    background-color: #ffffff;
    clip-path: circle(50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .round-image {
    width: 95%; /* Adjust the width of the image */
    height: 95%;
    border-radius: 50%; /* Make the image round */
  }