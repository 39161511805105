.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  background-color: var(--color-primary);
  z-index: 10;
  font-size: 16px;
  color: white;
  text-align: end;
  padding: 5px;
}
