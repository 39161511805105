.tableimg {
    width: 150px;
    height: 75px;
    object-fit: cover;
}
.textLabelField{
    color: black;
    border: none;
    padding: 7px;
    font-size: 1.5rem;
    // box-shadow: 0 0 4px 0 hsla(0,0%,50%,.3607843137);
    box-shadow: none;
    transition: all 0.2s; 
    text-align: center;
    width: 22rem;
    border-radius: 2rem; 
    overflow-wrap:break-word;
    font-weight: 800;
}
 