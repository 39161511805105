:root {
   //--color-primary: #217fb5;
  --color-primary: #217B9F;
  --color-primary-light: #1c6d9c;
  --color-primary-light-rgda: rgb(28, 109, 156, 0.6);
  --color-primary-dark: #ba265d;

  --color-secondary: #e78021;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-5: #fbfbfb;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;
  --color-white: #fff;
  --color-grey-dark-1: #333;
  --color-grey-dark-2: #777;
  --color-grey-dark-3: #999;
  --linear-gradient: linear-gradient(to right, #1974b5, #13599a);

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  // --shadow-light: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --shadow-light: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  --shadow-drak: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --line: 1px solid var(--color-grey-light-2);
  // box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: var(--color-primary);
}
$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

html {
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
}
body {
  background-color: var(--color-white);
  font-family: myfontregular!important;
  overflow-x: hidden;
  line-height: 1.3!important;
  font-size: 1.6rem!important;
}

main {
  margin: 2.5rem 3rem;
  @media screen and (max-width: 500px) {
    margin: 2.5rem 0.5rem;
  }
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: end;
}
.flex-container-responsive {
  display: flex;
  @media screen and (max-width: 800px) {
    display: block!important;
  }
}
.responsive {
  display: flex!important;
  align-items: center;
}
.responsiveOverview {
  @media screen and (max-width: 800px) {
    display: block!important;
  }
}
.responsiveImage {
  height: 200px;
  @media screen and (max-width: 800px) {
    height: 100%;
    width: 100%;
  }
}
.modulesSubHeadResponsive {
  @media screen and (max-width: 1205px) {
    padding: 0.5rem 1rem!important;
  }
}
.grid-item2 {
  @media screen and (max-width: 800px) {
    grid-row: auto!important;
    padding: 0!important;
  }
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 550px) {
    display: block;
  }
}
.mx {
  margin: 0 2rem;
}
.my {
  margin: 2rem 0;
}
.ml {
  margin-left: 25px;
}
.mr {
  margin-right: 25px;
}

.swiper-button-prev {
  left: 15px !important;
  color: #777777;
  font-size: 2.5rem !important;
  height: 20px !important;
  &:hover {
    color: #777777;
    transform: scale(1.1);
    transition: transform 0.5s;
    font-weight: 600;
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
}
.swiper-button-next {
  right: 15px !important;
  color: #777777;
  font-size: 2.5rem !important;
  height: 20px !important;

  &:hover {
    color: #777777;
    transform: scale(1.1);
    transition: transform 0.5s;
    font-weight: 600;
  }
  @media screen and (max-width: 500px) {
    display: none;
    // margin: 0 1rem;
  }
}

::selection {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.spinnerStyle {
  width: 40px !important;
  height: 40px !important;
  z-index: 999 !important;
  overflow: visible !important;
  position: fixed !important;
}
.spinnerStyle:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.swiper-button-disabled {
  display: none !important;
}

.exploreMain {
  @media screen and (min-width: 1366px) and (max-width: 1400px) {
    margin: 2 1rem;
  }
}

.viewAllActive {
  color: var(--color-primary) !important;
  &:active {
    color: var(--color-primary);
  }
}

.e-schedule
  .e-schedule-toolbar
  .e-toolbar-items
  .e-toolbar-item.e-date-range
  .e-tbar-btn
  .e-tbar-btn-text {
  font-weight: 600;
  font-size: 14px;
  text-transform: initial;
}

@keyframes loader-animation {
  0% {
    width: 0%;
  }
  100% {
    left: 0%;
    width: 100%;
  }
}
.loader {
  height: 3px;
  width: 100%;
}
.loader .bar {
  position: absolute;
  height: 3px;
  background-color: var(--color-primary);
  animation-name: loader-animation;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

h2,
h3 {
  font-size: 2rem;
}
@font-face {
  font-family: 'myfontbold';
  src: url('../src/font/NunitoSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'myfontregular';
  src: url('../src/font/NunitoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'myfontblitalic';
  src: url('../src/font/NewsGothBTBoldItalic.ttf') format('truetype');
}

.MuiIconButton-root {
  color: #000000db !important;
}

.MuiInput-underline.Mui-error:after {
  border-bottom-color: #ED0000!important;
}

.MuiFormHelperText-root.Mui-error {
  color: #ED0000!important;
}

.e-schedule .e-month-view .e-date-header {
  font-weight: 600;
}

.e-toolbar .e-tbar-btn.e-btn .e-icons {
  color: black!important;
}
.swal2-container {
  z-index: 100000 !important;
}

.MuiButton-contained.Mui-disabled {
  background-color: #777777!important;
  color: #fff!important;
}

.MuiInputAdornment-root {
  height: 3rem!important;
  max-height: 3rem!important;
}