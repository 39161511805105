.jfwfields {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem; /* Space between form fields */
    width: 100%; /* Make the field take full width */
  }
  .jfwfields_one{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 1.5rem; /* Space between form fields */
    width: fit-content; /* Make the field take full width */
    border-radius: 1rem;
    background: radial-gradient(circle, rgba(212, 203, 203, 0.703) 60%, #f1f1f1);
    box-shadow: 0px 0px 8px rgba(13, 12, 12, 0.331);
  }
  .jfwfields_button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color:#217FB5;
    border-radius: 0.6rem;
    border:1px solid gray;
    cursor: pointer;
  }
  sup{
    color: red;
  }
  .jfwfields_button > p {
    font-size: 1.5rem;
  }
  .jfwfields label {
    margin-bottom: 0.5rem; /* Space between label and input */
    font-weight: bold; /* Make the label bold */
    font-size: 1.5rem; /* Set a consistent font size for labels */
  }
  .jfwfields input {
    padding: 0.5rem; /* Padding inside the input field */
    width: 100%; /* Make the input field fill the available width */
    border: 1px solid #ccc; /* Add a light border */
    border-radius: 4px; /* Rounded corners for inputs */
    font-size: 1.5rem; /* Set font size inside input fields */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    
  }
  .circle {
    width: 150px;
    height: 150px;
    border-radius: 50%; /* Creates the circular shape */
    background: radial-gradient(circle, #d4cbcbb3 60%, #f1f1f1); /* Mimics the 3D/shadow look */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc; /* Light border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds shadow for a 3D effect */
    font-size: 2rem;
    font-weight: bold;
    color: #1e90ff; /* Blue color for the number */
    margin: 2rem;
  }
  .circle:hover{
    box-shadow: 0 0px 8px rgba(45, 134, 235, 0.84);
  }
  .circle span {
    font-size: 2rem;
    color: #1e90ff; 
  }
  .subtitle{
    font-size: 10px;
    font-weight: bold;
  }
  .jfwfields textarea {
    width: 100%;  
    resize: vertical;  
    padding: 10px; 
    border-radius: 5px;  
    border: 1px solid #ccc;  
    box-sizing: border-box; 
    font-size: 1.5rem;
  }