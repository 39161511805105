/* Global Styles */

.paragraphONDC, .listONDC{
  color: #19486D;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  text-align: justify;
  font-size: 14px;
  margin: 0;
}
.paragraphONDCAbout {
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  text-align: justify;
  font-size: 14px;
  margin-top: 2rem;
  color:black;
  // color: #19486D;
}
.listONDCAbout {
  color:black;
  // color: #19486D;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  text-align: justify;
  font-size: 14px;
  margin: 0;
}
.boldONDC{
  color: #19486D;
  font-family: "Oxygen", sans-serif;
  text-align: left;
}
.buttonONDC {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.67rem 1.188rem;
  text-decoration: none;
  color: #3973b6;
  border: 1.5px solid #3973b6;
  border-radius: 0.75rem;
  background-color: transparent;
  cursor: pointer;
}
.sectionContainer.dark-mode .buttonONDC {
  color: #ffffff;
  border: 1.5px solid #ffffff;
}
.sectionContainer.dark-mode .buttonONDC a{
  color: #ffffff;
}
.h3ONDC {
  // font-size: 1.575rem;
  color: #19486D;
  font-family: "Oxygen", sans-serif;
  text-align: left;
  // line-height: 1.838rem;
  padding-right: 15px;
}
.h4ONDC {
  // font-size: 1.275rem;
  color: #19486D;
  font-family: "Oxygen", sans-serif;
  text-align: left;
  // line-height: 1.438rem;
  padding-right: 15px;
}
.cardONDC{
  width: 32rem;
  display: block;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.15) 0px 6px 12px -2px, rgba(0, 0, 0, 0.15) 0px 3px 7px -3px;
  border-radius: 1.5rem;
}
.cardONDC > div{
  padding: 1rem;
}

/* Style for the header navigation */
.headerONDC {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 0.75rem 2.5rem;
  border-bottom: 1px solid #e9f4fe;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #fafdff;
  justify-content: space-between;
}

.navONDC {
  display: inline-block;
}

.navONDC ul {
  list-style-type: none;
  padding: 0;
}

.navONDC ul li {
  display: inline;
  margin-right: 10px;
}

.header-logo {
  display: block;
  width: 12rem;
}

.header-menu {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

header .header-menu nav ul {
  display: flex;
  margin-left: 0;
  justify-content: flex-start;
  align-items: baseline;
}

header .header-menu nav ul>li a {
  font-size: 1.5rem;
  color: #8e8e8e;
  text-decoration: none;
  margin: 0 0.75rem;
  width: 100%;
  display: block;
  font-family: "Oxygen",sans-serif;
  font-weight: 400;
}

.mainHeader {
  font-size: 50px;
  color: #19486D;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  text-align: left;
  // line-height: 3.75rem;
  margin: 1rem 0 0.5rem;
  background: linear-gradient(180deg, #2A466B 0%, #3F77B0 100%);
  -webkit-background-clip: text;
  background-clip: text;-webkit-text-fill-color: transparent;
}

.mainSecondHeader {
  font-size: 35px;
  color: #19486D;
  text-align: center;
  margin-top: 6rem;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  // background: linear-gradient(90deg, #2A466B 46%, #3F77B0 51%);
  background: linear-gradient(to right, #19486d, #45b1ff);
  -webkit-background-clip: text;
  background-clip: text;-webkit-text-fill-color: transparent;
}

.mainSecondHeaderAbout {
  font-size: 35px;
  // color: #19486D;
  -webkit-text-fill-color: black;
  text-align: center;
  margin-top: 6rem;
  font-family: "Heebo", sans-serif;
  font-weight: 700;
  // background: linear-gradient(90deg, #2A466B 46%, #3F77B0 51%);
  background: linear-gradient(to right, #19486d, #45b1ff);
  -webkit-background-clip: text;
  background-clip: text;
  // -webkit-text-fill-color: transparent;
}

.sectionContainer.dark-mode .mainSecondHeaderAbout {
  color: #ffffff;
  background: transparent;
  -webkit-text-fill-color: white;
}

.sectionContainer.dark-mode .mainHeader {
  background: linear-gradient(180deg, #ffffff 0%, #c8e3ff 100%);
  -webkit-background-clip: text;
  background-clip: text;-webkit-text-fill-color: transparent;
}

.ptagBanner {
  color: #1E1F1F; margin-bottom: 1rem;
  font-size: 14px;
}

.sectionContainer.dark-mode .ptagBanner {
  color: #ffffff;
}


/* Style for the content blocks */
.sectionContainer {
  height: 100%;
  padding: 20px;
  text-align: center;
}

.sectionContainer h2 {
  margin-top: 6rem;
}

/* Style for the toggle button */
.toggle-btn {
  display: inline-block;
  background-color: transparent;
  border: none;
  color: #000;
  cursor: pointer;
  font-size: 18px;
  margin-left: 10px;
}

.toggle-btn:focus {
  outline: none;
}

/* Dark mode styles */
body.dark-mode {
  background-color: #333;
  color: #fff;
}

header.dark-mode {
  background-color: #e2e2e2;
  color: #fff!important;
}

.toggle-btn.dark-mode {
  color: #fff;
}

.sectionContainer.dark-mode {
  background-color: #222;
  border-bottom: 1px solid gray;
}
.sectionContainerDark.dark-mode {
  background-color: #343434;
}
.sectionContainer.dark-mode > h2 {
  background: linear-gradient(180deg, #ffffff 0%, #bfdfff 100%);
  -webkit-background-clip: text;
  background-clip: text;
}
.sectionContainer.dark-mode > div >div> p {
  color: #fff;
}
.sectionContainer.dark-mode > div >div > ol > li {
  color: #fff;
}
.sectionContainer.dark-mode > div >div > ol > li > b{
  color: #fff;
}
.sectionContainer.dark-mode > div >div> h2 {
  color: #fff!important;
}
.sectionContainer.dark-mode > div >div> h3 {
  color: #fff!important;
}

.imageTextContainer {
  display: flex;
  gap: 10rem;
  margin: 0rem 5rem;
  align-items: center;
}

@media only screen and (max-width: 769px) {
  .imageTextContainer {
    display: block;
    margin: 1rem;
  }
  .BannerImage {
    width: 43vw!important;
    border-radius: 2rem!important;
  }
  .mainHeader {
    font-size: 3rem;
    // line-height: 1.75rem;
  }
  .aboutContainer {
    display: grid!important;
    margin: 1rem!important;
    gap: 2rem!important;
  }
  .aboutContainer > div{
    width: 100%!important;
  }
  .prev {
    padding: 2px!important;
  }
  .next {
    padding: 2px!important;
  }
  .header-menu {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .mobileMenuItems {
    display: none;
  }
}

.BannerImage {
  width: 43rem;
  border-radius: 5rem;
}


/* Slde */
.mySlides {display: none}
.imgONDC {vertical-align: middle;}

/* Slideshow container */
.slideshow-container {
max-width: 100%;
position: relative;
margin: 12rem 0rem 0rem;
height: 41rem;
display: flex;
align-items: center;
}
@media only screen and (max-width: 769px) {
.slideshow-container {
  margin: 6rem 0rem 0rem;
  height: 42rem;
  align-items: center;
  display: flex;
}
}

/* Next & previous buttons */
.prev {
cursor: pointer;
position: absolute;
top: 50%;
left: 0;
width: auto;
padding: 16px;
margin-top: -22px;
/* color: white; */
font-weight: bold;
font-size: 18px;
transition: 0.6s ease;
border-radius: 0 3px 3px 0;
color: #19486D;
user-select: none;
}
.sectionContainer.dark-mode .prev {
color: #cbe5ff;
}
.next {
cursor: pointer;
position: absolute;
top: 50%;
right: 0;
width: auto;
padding: 16px;
margin-top: -22px;
/* color: white; */
font-weight: bold;
font-size: 18px;
transition: 0.6s ease;
border-radius: 0 3px 3px 0;
user-select: none;
}

/* Position the "next button" to the right */
.next {
right: 0;
border-radius: 3px 0 0 3px;
color: #19486D;
}
.sectionContainer.dark-mode .next {
color: #cbe5ff;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
background-color: rgba(0, 0, 0, 0.111);
}

/* Caption text */
.text {
/* color: #f2f2f2; */
font-size: 15px;
padding: 8px 12px;
position: absolute;
bottom: 8px;
width: 100%;
text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
/* color: #f2f2f2; */
font-size: 12px;
padding: 8px 12px;
position: absolute;
top: 0;
}

/* The dots/bullets/indicators */
.dot {
cursor: pointer;
height: 17px;
width: 20px;
margin: 0 2px;
background-color: #ffffff;
border-radius: 50%;
display: inline-block;
transition: background-color 0.6s ease;
border: 1px solid #40A5EE;
}

.dot .active, .dot:hover {
background: linear-gradient(90deg, #19486D, #40A5EE);
border: 1px solid #40A5EE;
}
.dot:active {
background: linear-gradient(90deg, #19486D, #40A5EE)!important;
border: 1px solid #40A5EE;
}

/* Fading animation */
.fade {
animation-name: fade;
animation-duration: 1.5s;
}

@keyframes fade {
from {opacity: .4} 
to {opacity: 1}
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
.prev, .next,.text {font-size: 11px}
}


/* Accordion styles New*/
.accordion {
max-width: 100%;
list-style: none;
background: linear-gradient(144.37deg, #EBF4FD 24.48%, #FBFFF0 109.39%);
border-radius: 30px;
border: 0px solid rgba(0,0,0,.125);
border-bottom: 1px solid #dcebf4;
padding: 1rem;
margin: 2rem 0rem;
}

/* Style the accordion sections */
.accordion input[type="checkbox"] {
display: none;
}

.accordion label {
display: block;
// background-color: #f9f9f9;
color: #444;
cursor: pointer;
padding: 18px;
width: 100%;
// border-bottom: 1px solid #ddd;
font-size: 15px;
transition: background-color 0.3s;
position: relative;
}

.accordion label:before {
content: "+";
position: absolute;
right: 10px;
transition: transform 0.3s;
border: 1px solid #19486d;
border-radius: 50%;
width: 25px;
height:25px;
font-size: 1.7rem;
font-weight: 900;
}

.accordion input[type="checkbox"]:checked ~ label:before {
content: "-";
font-size: 1.7rem;
font-weight: 900;
}

/* Hide the accordion content by default */
.accordion .content {
max-height: 0;
overflow: hidden;
transition: max-height 0.3s;
}

/* Show the accordion content when checkbox is checked */
.accordion input[type="checkbox"]:checked ~ .content {
max-height: 615px; /* Adjust this value to determine the maximum height of the content */
transition: 0.6s ease;
}

/* Accordion styles */
.accordionContainer {
background-color: #fff;
border: 1px solid #cfdee9;
border-radius: 30px;
padding: 30px;
margin: 3rem 1rem 0rem;
}
.sectionContainer.dark-mode .accordionContainer {
background-color: #222222 !important;
}

.accordionONDC {
padding: 0;
margin: 4rem 0rem;
}
.accordionONDC > li {
list-style: none;
background: linear-gradient(144.37deg, #EBF4FD 24.48%, #FBFFF0 109.39%);
border-radius: 30px;
border: 0px solid rgba(0,0,0,.125);
margin: 15px;
border-bottom: 1px solid #dcebf4;
padding: 1rem;
}
.accordionONDC li label{
justify-content: space-between;
display: flex;
align-items: center;
padding: 20px 10px;
font-size: 1.5rem;
font-weight: 500;
cursor: pointer;
}
// .labelONDC::after {
//   content: "+";
//   margin-left: 10px;
//   font-size: 2rem;
//   border: 1px solid #19486D;
//   border-radius: 2rem;
//   width: 28px;
//   text-align: center;
// }
.listONDC > input[type="radio"] {
display: none;
}
.accordionONDC .content {
color: #19486D;
padding: 0 10px;
line-height: 24px;
max-height: 0;
overflow: hidden;
transition: max-height 0.2s, padding 0.2s;
}
.accordionONDC input[type="radio"]:checked + label + .content {
max-height: 400px;
padding: 10px 10px 20px;
}
.accordionONDC input[type="radio"]:checked + label::after {
content: "-";
margin-left: 10px;
font-size: 2rem;
}

.FAQbutton {
background: linear-gradient(90deg, #40A5EE, #19486D)!important;
color: white;
font-weight: 700;
margin: 2rem 0rem;
}

@media only screen and (max-width: 769px) {
.accordionContainer {
  padding: 1rem;
  margin: 2rem 0rem;
}
}


/* Footer  */
.footerONDC {
background-color: #b3d4fc;
padding: 1rem 0rem 2rem;  
}
.footerLinks {
color: #454545;
//font-size: 0.85rem;
margin: 1.5rem 0rem;
text-decoration: none;
  font-family: "Oxygen", sans-serif;
  font-weight: 400;
  text-align: left;
}
.footerLinks:hover {
color: #19486D;
}

.footerIcons {
display: flex;
gap: 1rem;
padding: 1rem 0;
justify-content: center;
}

.footerIcons > a{
/* background-color: #333; */
border-radius: 1rem;
// font-size: 1.25rem;
color: #454545;
text-decoration: none;
}

.footerIcons > a:hover {
color: #19486D;
}

.footerLinksContainer {
display: grid;
}

.footerLinksContainer > a {
font-size: 1rem;
text-decoration: none;
font-size: 1.5rem;
}

.footerLinksContainer > a:hover {
color: #19486D;
font-weight: 500;
}

.contactUsONDC {
display: flex;
align-items: center;
gap: 20px;
}

.footerONDCContainer {
display: flex;
gap: 0rem;
padding-bottom: 1rem;
margin: 1rem 6rem 1rem;
flex-wrap: wrap;
justify-content: space-between;
border-bottom: 1px solid rgb(212, 212, 212);
}

.InnerFooterONDC {
display: flex;
gap: 3rem;
margin: 1rem 6rem 0rem;
flex-wrap: wrap;
justify-content: space-between;
align-items: stretch;
}

@media only screen and (max-width: 769px) {
.contactUsONDC {
  display: block;
}
.footerONDCContainer {
  display: grid;
  margin: 1rem 4rem 1rem;
}
.InnerFooterONDC {
  display: grid;
  margin: 1rem 4rem 1rem;
}
}





.aboutButton {
// font-size: 1.25rem;
  font-family: "Heebo", sans-serif;
  font-weight: 500;
  color: #E4A059;
  background: #FEF8EC;
  border: 1px solid #DFB682;
  border-radius: 12px;
  width: fit-content;
  line-height: unset;
  padding: 0.6rem 1.313rem;
  height: auto;
}

.aboutText {
color: #19486D;
font-family: "Oxygen", sans-serif;
font-weight: 400;
max-width: 545px;
text-align: left;
// line-height: 1.438rem;
padding-right: 15px;
}

.aboutContainer {
display: flex;gap: 3rem; margin: 4rem 3rem 4rem;align-items: center;
}


.aboutSection {
background-color: #ffffff;
}

.sectionContainer.dark-mode .aboutSection {
background-color: black;
}

.olONDC {
padding: 10px 10px 20px 50px;
}

.aboutContentONDC {
width: 80%;
margin-left: 2rem;
margin-bottom: 5rem;
}
.aboutHeadingONDC {
margin-top: 2rem!important;
margin-bottom: 0.5rem;
font-size: 4rem;
text-align: justify;
}


@media only screen and (max-width: 769px) {
.olONDC {
  padding: 10px 0px 0px 20px;
}
.aboutContentONDC {
  width: 100%;
  margin-left: 0;
  margin-bottom: 1rem;
}
.aboutHeadingONDC {
  font-size: 2.5rem;
  // text-align: left;
}
}



/* Mobile navigation styles */
  .mobile-nav {
    display: none;
  }
  
  .menu-icon {
    display: inline-block;
    cursor: pointer;
  }
  
  .menu-icon span {
    width: 30px;
    height: 5px;
    background-color: #333;
    margin-bottom: 6px;
    display: block;
  }
  
  .menu {
    display: none;
    padding: 10px;
  }
  
  .menu a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
  }
  
  /* Desktop navigation styles */
  @media only screen and (min-width: 768px) {
    .mobile-nav {
      display: none;
    }
    
    .menu {
      display: block;
    }
    
    .menu-icon {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .blinking-shadow {
      margin: 1rem!important;
    }
    .blinking-shadow-checked {
      margin: 1rem!important;
    }
    .checkedSuccess {
      margin-left: 4rem!important;
    }
  }


  .mobileNavONDC {
    width: 100%;
  }


  .mobileNavONDC ul>li a {
    font-size: 1.5rem;
    color: #8e8e8e;
    text-decoration: none;
    margin: 0 0.75rem;
    width: 100%;
    display: block;
    font-family: "Oxygen",sans-serif;
    font-weight: 400;
  }
  .mobileNavONDC ul>li {
    display: block;
    line-height: 5rem;
    border-bottom: 1px dashed #d4d4d4;
  }

  .mobileNavONDC ul{
    margin: 1.5rem 2.3rem;
    text-align: left;
    padding: 0;
  }
  .FAQul {
    text-align: left;
    color: #19486D;
  }

  .FAQul li::marker {
    color:  #1C75BC;
    font-weight: bold;
    display: inline-block; 
    width: 4px;
    height: 4px;
  }
  
  .imgAbout {
    width: 45rem;
    margin: 2rem 0rem;
  }
  @media only screen and (max-width: 500px) {
    .imgAbout {
      width: 20rem;
    }
  }

  @keyframes blink-shadow {
    0% {
      box-shadow: 0 0 0 #40A5EE;
    }
    50% {
      box-shadow: 0 0 4px 2px #257cc0;
    }
    100% {
      box-shadow: 0 0 0 #ebebeb;
    }
  }
  
  .blinking-shadow {
    animation: blink-shadow 1.8s infinite; /* Run the animation infinitly */
    width: fit-content;
    margin: 1rem 1rem 1rem 3.7rem;
    align-items: center;
    display: flex;
    gap: 1rem;
    border-radius: 5px;
    padding: 0px 5px;
  }

  .blinking-shadow-checked {
    animation: blink-shadow 1.8s ; /* Run the animation infinitly */
    width: fit-content;
    margin: 1rem 1rem 1rem 3.7rem;
    align-items: center;
    display: flex;
    gap: 1rem;
    border-radius: 5px;
    padding: 0px 5px;
  }

  .blinking-shadow:hover {
    animation-play-state: paused;
  }

  .CheckBoxLinks {
    color: #40A5EE;
    margin: 1.5rem 0rem;
    // text-decoration: none;
    font-family: "Oxygen", sans-serif;
    font-weight: 400;
    text-align: left;
  }
  .checkedSuccess {
    margin: 1rem 1rem 1rem 6.7rem;
    text-align: left;
    font-size: 14px;
  }