.card-b {
  height: 330px;
  width: 350px;
  cursor: pointer;

  .renderBgColor {
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.8rem 0.8rem 0 0;
    @media screen and (min-width: 900px) and (max-width: 1279px) {
      height: 170px;
      width: 100%;
    }
    @media screen and (min-width: 1279px) and (max-width: 1400px) {
      height: 180px;
      width: 100%;
    }
  }
  @media screen and (max-width: 785px) {
    height: 310px;
    width: 160px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1023px) {
    height: 310px;
    width: 170px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1124px) {
    height: 310px;
    width: 230px;
    // margin: 0 1rem;
  }

  @media screen and (max-width: 1289px) {
    height: 320px;
    width: 260px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1315px) {
    height: 320px;
    width: 290px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1360px) {
    height: 320px;
    width: 300px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1366px) {
    height: 320px;
    width: 300px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1400px) {
    height: 320px;
    width: 310px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1450px) {
    height: 330px;
    width: 310px;
    // margin: 0 1rem;
  }
  @media screen and (max-width: 1525px) {
    height: 330px;
    width: 335px;
    // margin: 0 1rem;
  }

  .padding {
    padding: 1rem 0.5rem;
    @media screen and (max-width: 1440px) {
      padding: 1rem 0.5rem;
    }
  }
  .padding-lg {
    height: 67px;
    padding: 0 2rem 0rem 2rem;

    @media screen and (max-width: 1440px) {
      height: 75px;
      line-height: 1.3;
      padding: 0 2rem 0rem 2rem;
    }
  }
  .padding-p {
    height: 95px;
    padding: 1rem 2rem 0rem 2rem;
  }
  h4 {
    font-weight: bold;
    @media screen and (max-width: 1279px) {
      font-size: 14px;
    }
    @media screen and (max-width: 1439px) {
      font-size: 15.4px;
    }
  }
  margin: 0 1rem 0 0;
  box-shadow: var(--shadow-light);
  background-color: #fff;
  border-radius: 0.8rem 0.8rem 0.8rem 0.8rem;
  .img-container {
    min-width: 100%;
    min-height: 190px;
    border-radius: 0.8rem 0.8rem 0 0;

    @media screen and (max-width: 1279px) {
      min-width: 100%;
      min-height: 170px;
    }
    @media screen and (max-width: 1439px) {
      min-width: 100%;
      min-height: 180px;
    }

    img {
      width: 100%;
      height: 190px;
      border-radius: 0.8rem 0.8rem 0 0;
      @media screen and (max-width: 1279px) {
        width: 100%;
        height: 170px;
      }
      @media screen and (max-width: 1439px) {
        width: 100%;
        height: 180px;
      }
    }
  }
}

.audioiconDardk {
  -webkit-mask-image: url("../../assets/media/objects/audiosvg.svg");
  mask-image: url("../../assets/media/objects/audiosvg.svg");
  //background-image: url('../../assets/media/objects/audio.png');
}

.bgimage {
  background-repeat: no-repeat;
  background-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-size: cover;
  width: 35px;
  height: 35px;
  margin-right: 0.5rem;
}

.modules {
  //   background-image: url('../../../assets/media/modules.png');
}
